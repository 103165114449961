import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Resume from '../../../../content/resume.pdf';
import { Wrapper } from './styles'

export default ({ desktop }) => (
	<Wrapper desktop={desktop}>
		<AnchorLink href="#about">About</AnchorLink>
		<AnchorLink href="#projects">Projects</AnchorLink>
		<AnchorLink href="#contact">Contact</AnchorLink>
		<a href={Resume} target = "_blank">Resume</a>
	</Wrapper>
)
